<template>
  <div class="layout">
    <div class="header">
      <div class="header-main">
        <div class="title">
          <img src="@/assets/images/logo.png" alt class="logo" />
          <div>厨余垃圾数字监管平台</div>
        </div>
        <div class="left">
          <div :class="titIndex == 1 ? 'indexBox indexBox-active' : 'indexBox'" @click="setTitIndex(1)">
            废弃油脂
          </div>
          <!-- <div
          :class="titIndex == 2 ? 'indexBox indexBox-active' : 'indexBox'"
          @click="setTitIndex(2)"
        >
          餐厨垃圾
        </div> -->
        </div>
        <div class="right">
          <div class="menu">
            <!-- <router-link to="/merchantsin" class="menu-item">商户分析</router-link> -->
            <router-link to="/analysis" class="menu-item" style="margin-right: 180px;">收运分析</router-link>
            <div class="logout" style="margin-right: 20px">
              <img src="@/assets/img/user.png" alt="" style="margin-right: 6px" />
              <span>{{ userInfo.name }}</span>
            </div>
            <div class="logout" @click="logout">
              <img src="@/assets/img/log-out .png" alt="" style="margin-right: 6px" />
              <span>退出</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <router-view :key="key" />
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { userInfoApi } from "@/api/login/login";

export default {
  computed: {
    key() {
      return this.$route.path;
    },
  },
  data() {
    return {
      titIndex: 1,
      userInfo: {
        name: "",
      },
    };
  },
  created() {
    store.commit("setTitIndex", this.titIndex);
    this.getUserInfo();
  },

  methods: {
    setTitIndex(e) {
      this.titIndex = e;
      store.commit("setTitIndex", e);
    },
    getUserInfo() {
      userInfoApi().then((res) => {
        // console.log(res);
        this.userInfo.name = res.data.name;
        sessionStorage.setItem("userInfo", JSON.stringify(res.data));
      });
    },
    logout() {
      window.sessionStorage.clear();
      this.$router.replace("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  color: #ffffff;
  background: #050b20;
  width: 1920px;
  height: 1080px;
  position: relative;
}

.header {
  width: 100%;
  height: 80px;
  background: url("@/assets/images/layout/header.png") no-repeat;
  background-size: 100%;
  position: relative;
  padding: 0 18px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.header-main {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .title {
    font-size: 36px;
    height: 80px;
    line-height: 80px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      width: 55px;
      height: 55px;
      margin-right: 20px;
    }
  }

  .left {
    // float: left;
    position: absolute;
    // left: vw(360);
    // top: vh(20);
    left: 0;
    // top: 0;
    // display: flex;
    // align-items: center;

    .indexBox {
      width: 170px;
      height: 45px;
      line-height: 45px;
      background: url("@/assets/img/default.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      text-align: center;
      color: #d8e7ee;
      text-decoration: none;
      font-size: 18px;
    }

    .indexBox-active {
      background: url("@/assets/img/click.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .right {
    // float: right;
    position: absolute;
    right: 0;
    // top: vh(20);
  }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;

  .menu-item {
    width: 120px;
    height: 45px;
    line-height: 45px;
    background: url("@/assets/images/menu.png") no-repeat;
    background-size: 100% 100%;
    margin-right: 30px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    font-size: 18px;
  }

  .router-link-active {
    background: url("@/assets/images/menu_active.png") no-repeat;
    background-size: 100% 100%;
  }
}

.logout {
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    height: 24px;
    width: 24px;
  }

  span {
    font-size: 18px;
    font-weight: 400;
    color: #c2e0fc;
  }
}

.container {
  position: relative;
  padding-top: 80px;
  height: 100%;
  overflow: hidden;
}
</style>
