import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // isAuthenticated: sessionStorage.getItem("isAuth") || false,
    token: sessionStorage.getItem("token"),
    titIndex: 1
  },
  getters: {},
  mutations: {
    setTitIndex: (state, e) => {

      state.titIndex = e
    },
    SET_TOKEN: (state, token) => {
      // state.isAuthenticated = true;
      state.token = token;
      sessionStorage.setItem("token", token);
      // sessionStorage.setItem("isAuth", true);
    },
  },
  actions: {},
  modules: {},
});
