import axios from "axios";
import { Message } from "element-ui";
import router from "@/router";
import store from "@/store";

let showMsg = true;

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000,
});

service.interceptors.request.use(
  (config) => {
    const token = store.state.token;
    const name = router.currentRoute.name;

    if (token && name !== "Login") {
      config.headers["token"] = token; // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code === 200) {
      return Promise.resolve(res);
    } else {
      if (showMsg) {
        Message({
          message: res.code === 401 ? "登录过期" : res.msg || "Error",
          type: "error",
          duration: 3 * 1000,
        });
        showMsg = false;
        setTimeout(() => {
          showMsg = true;
        }, 3000);
        if (res.code === 401) {
          sessionStorage.removeItem("token");
          router.replace({ path: "/login" });
        }
      }

      return Promise.reject(new Error(res.msg || "Error"));
    }
  },
  (error) => {
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
