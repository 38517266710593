import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import Layout from "@/layout/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: "/index",
    children: [
      {
        path: "index",
        name: "Index",
        component: () => import("@/views/index/index"),
      },
      {
        path: "merchantsin",
        name: "MerchantsIn",
        component: () => import("@/views/merchantsIn/index"),
      },
      {
        path: "analysis",
        name: "analysis",
        component: () => import("@/views/analysis/index"),
      },
      {
        path: "analysisInfo",
        name: "analysisInfo",
        component: () => import("@/views/analysis/info"),
      },
      {
        path: "merchantsinInfo",
        name: "merchantsinInfo",
        component: () => import("@/views/merchantsIn/info"),
      },


    ],
  },

  // {
  //   path: "/about",
  //   component: Layout,
  //   redirect: "/about/index",
  //   children: [
  //     {
  //       path: "index",
  //       name: "About",
  //       component: () => import("@/views/about/index"),
  //     },
  //   ],
  // },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach((to, from, next) => {
  const token = store.state.token;
  if (to.name !== "Login" && !token) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
