import request from "@/utils/request.js";

/**
 * 登录
 */
export function loginIn(data) {
  return request({
    url: "/auth/dataScreen/doLogin",
    method: "POST",
    data,
  });
}

/**
 * 用户信息
 */
export function userInfoApi() {
  return request({
    url: "/auth/b/getLoginUser",
    method: "GET",
  });
}

